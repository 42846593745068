import "./Login.css"
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { InputOtp } from "primereact/inputotp";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { StringBuilder } from "../core/String";
import Storage from "../core/Storeage";
import { isEmpty } from "../core/String";
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';

function Login() {
	const countries = [
        { name: "Россия", code: "+7", mask: "999 999-99-99" },
        { name: "Беларусь", code: "+375", mask: "99 999-99-99" },
		{ name: "Казахстан", code: "+7", mask: "999 999-99-99" },
		{ name: "Армения", code: "+374", mask: "999 999-99-99" },
		{ name: "Киргизия", code: "+996", mask: "999 999 999" },
		{ name: "Узбекистан", code: "+998", mask: "99 999-99-99" },
		{ name: "Абхазия", code: "+995", mask: "9 999-99-99" }
    ];

	const [phoneOrMail, setPhoneOrMail] = useState(null);
	const [password, setPassword] = useState(null);
	const [phone, setPhone] = useState(null);
	const [code, setCode] = useState(null);
	const [isDisabledControls, setIsDisabledControls] = useState(false);
	const [isInvalidPhoneOrMail, setIsInvalidPhoneOrMail] = useState(false);
	const [isInvalidPassword, setIsInvalidPassword] = useState(false);
	const [isInvalidPhone, setIsInvalidPhone] = useState(false);
	const [isDisabledPhone, setIsDisabledPhone] = useState(false);
	const [isInvalidCountryPhoneCode, setIsInvalidCountryPhoneCode] = useState(false);
	const [isDisabledInputCode, setIsDisabledInputCode] = useState(true);
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const [isDisabledCountryPhoneCode, setIsDisabledCountryPhoneCode] = useState(false);
	const toast = useRef(null);
	const storage = new Storage();
	const navigate = useNavigate();

	function showError(msg) {
		toast.current.show({severity:"error", summary: "Ошибка", detail:msg, life: 4000});
    }

	useEffect(() => {
		document.title = `${process.env.REACT_APP_NAME}. Аутентификация`;
	}, []);

	function validateFormSms() {
		let isInvalidPhoneNumber = isEmpty(phone);
		setIsInvalidPhone(isInvalidPhoneNumber);
		return !isInvalidPhoneNumber;
	}

	function validateFormPassword() {
		let isInvalidPwd = isEmpty(password);
		let isInvalidLogin = isEmpty(phoneOrMail);

		setIsInvalidPassword(isInvalidPwd);
		setIsInvalidPhoneOrMail(isInvalidLogin);

		return !(isInvalidPwd | isInvalidLogin);
	}

	function getToken() {
		if (!validateFormPassword()) return;
		
		const url = new URL(process.env.REACT_APP_API_URL_AUTHENTICATION,
			process.env.REACT_APP_API_URL_BASE);
		
		let authData;

		if (phoneOrMail.includes("@")) {
			authData = {
				mail: phoneOrMail.trim(),
				password: password
			};
		}
		else {
			const sb = new StringBuilder();
			for (let i = 0; i < phoneOrMail.length; i++) {
				let char = phoneOrMail[i];
				let code = char.charCodeAt(0);
				if (code > 47 & code < 58) sb.append(char); 
			}

			let phoneNumber = sb.toString();

			authData = {
				phone: phoneNumber.length === 0 ? null : phoneNumber,
				password: password
			};
		}

		setIsDisabledControls(true);

		axios.post(url, authData)			
			.then((response) => {
				storage.SetSessionId(response.data.id);
				navigate("/");
			})
			.catch((error) => {
				if (error.response === undefined) {
					showError("Сервис недоступен. Проверьте подключение к интернету.");
				}
				else if (error.response.data === undefined) {
					showError("Код состояния: " + error.response.status);
				}
				else {
					showError(error.response.data.message);	
				}
			})
			.finally(() => {
				setIsDisabledControls(false);
			});
	}

	function selectedCountryItemTemplate(opt) {
		return (<span>{opt.name} <b>{opt.code}</b></span>);
	}

	function onSelectedCountry(e) {
		setIsInvalidCountryPhoneCode(!e.value);
		setSelectedCountry(e.value);
		setIsDisabledPhone(!e.value);
	}

	function onPressBtnSendCode() {
		setIsInvalidPhone(!phone);
		setIsDisabledInputCode(!phone);
		setIsDisabledCountryPhoneCode(phone);
		setIsDisabledControls(phone);
		setIsDisabledPhone(phone);
	}
	
	return (
		<div className='box p-2 text-center surface-card sm:shadow-5'>
			<h1 className='text-500 text-3xl font-medium mb-3'>Аутентификация</h1>
			<TabView className='sm:pl-5 sm:pr-5 pt-4'>
				<TabPanel header="по паролю" disabled={isDisabledControls} onKeyDown={(e) => { if (e.key === "Enter") getToken(); }}>
					<div className='pt-3 pb-3'>
						<label htmlFor="login" className="block text-900 font-bold mb-2 text-left">Телфеон или почта</label>
						<InputText
							id="login"
							type="text"
							placeholder="Телефон / почта"
							className="w-full mb-3"
							value={phoneOrMail}
							onChange={(e) => setPhoneOrMail(e.target.value)}
							disabled={isDisabledControls}
							invalid={isInvalidPhoneOrMail} />

						<label htmlFor="password" className="block text-900 font-bold mb-2 mt-1 text-left">Пароль</label>
						<Password
							style={{display: 'block'}}
							inputClassName="w-full"
							className="mb-2"
							id="password"
							placeholder="Пароль"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							feedback={false}
							toggleMask
							disabled={isDisabledControls}
							invalid={isInvalidPassword} />
						
						{ isDisabledControls ? 
							<ProgressBar mode="indeterminate" className='sm:ml-7 sm:mr-7' style={{ height: "6px", marginTop: "61px" }} /> :
							<Button loading={isDisabledControls} label="Войти" className="w-full mt-4" onClick={getToken} />
						}
					</div>
				</TabPanel>
				
				<TabPanel header="по SMS" disabled={isDisabledControls}>
					<div className='pt-3 pb-3'>
						<label htmlFor="phone" className="block text-900 font-bold mb-2 text-left">Телфеон</label>
						
						<div className="flex mb-3 gap-3">
							<Dropdown
								value={selectedCountry}
								itemTemplate={selectedCountryItemTemplate}
								onChange={onSelectedCountry}
								filter options={countries}
								optionLabel="code"
								placeholder="Код страны"
								className="w-4"
								checkmark={true}
								highlightOnSelect={false}
								invalid={isInvalidCountryPhoneCode}
								disabled={isDisabledCountryPhoneCode} />
							<InputMask
								className="w-full"
								mask={selectedCountry?.mask}
								placeholder={selectedCountry?.mask}
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								disabled={isDisabledControls | isDisabledPhone}
								invalid={isInvalidPhone} />
						</div>
						
						<label htmlFor="code" className="block text-900 font-bold mb-2 text-left" style={{marginTop: "18px"}}>Код</label>
						<InputOtp
							integerOnly
							disabled={isDisabledInputCode}
							value={code}
							onChange={(e) => setCode(e.target.value)}
							style={{marginTop: "-1px"}} />
						<div className="mb-2"></div>
						<Button
							label="Отправить код для входа"
							className="w-full mt-4"
							disabled={isDisabledControls}
							onClick={onPressBtnSendCode} />
					</div>
				</TabPanel>
			</TabView>
			
			<Toast ref={toast} position="top-center" />
		</div>
	);
}

export default Login;