import "./style/TopBar.css";
import 'primeicons/primeicons.css';
import axios from "axios";
import React, { useRef, useState} from 'react';
import { Menu } from 'primereact/menu';
import { Link } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { useNavigate } from "react-router-dom";
import Storage from "../core/Storeage";
import { classNames } from 'primereact/utils';

function TopBar(props) {
	const storage = new Storage();
	const navigate = useNavigate();
	const toast = useRef(null);
	const profileMenu = useRef(null);
	const topbarmenuRef = useRef(null);
	const profileMenuItems = [
		{
			label: 'Мой профиль',
			items: [
				{
					label: "Настройки",
					icon: "pi pi-cog"
				},
				{
					label: "Выход",
					icon: "pi pi-sign-out",
					command: () => {
						const url =
							new URL(process.env.REACT_APP_API_URL_LOGOUT, process.env.REACT_APP_API_URL_BASE);

						axios.request
							({
								headers: { Authorization: `Bearer ${storage.GetSessuinId()}` },
								method: "DELETE",
								url: url
							})
							.finally(() => {
								storage.RemoveSessionId();
								navigate("/login");	
							});
					}
				}
			]
		}
	];

	const [layoutState, setLayoutState] = useState({
		staticMenuDesktopInactive: false,
		overlayMenuActive: false,
		profileSidebarVisible: false,
		configSidebarVisible: false,
		staticMenuMobileActive: false,
		menuHoverActive: false
    });

	function onPressButtonMenu() {
		if (props.command) {
			props.command();
		}
	}

	function onPressButtonGroup() {
		setLayoutState((prevLayoutState) => ({ ...prevLayoutState, profileSidebarVisible: !prevLayoutState.profileSidebarVisible }));
	}

	return (
		<div className="layout-topbar">
			<Link to="/" className="layout-topbar-logo" style={{ width: "300px" }}>
				<span>Выбор филиала</span>
			</Link>

			<button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onPressButtonMenu}>
				<i className="pi pi-bars" />
			</button>

			<button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={onPressButtonGroup}>
				<i className="pi pi-ellipsis-v" />
            </button>

			<div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
				<button type="button" className="p-link layout-topbar-button" onClick={(event) => profileMenu.current.toggle(event)} aria-controls="popupProfileMenu" >
					<i className="pi pi-user"></i>
					<span>Мой профиль</span>
				</button>
				<Toast ref={toast} />
				<Menu model={profileMenuItems} ref={profileMenu} popup id="popupProfileMenu" />
			</div>
		</div>
	);
}

export default TopBar;