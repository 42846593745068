class Storage {
    _sidName = "sid";

    GetSessuinId() {
		return localStorage.getItem(this._sidName);
    }
	
	SetSessionId(value) {
		localStorage.setItem(this._sidName, value);
	}

	RemoveSessionId() {
		localStorage.removeItem(this._sidName);
	}
}

export default Storage;