import Storage from "../core/Storeage";
import { isEmpty } from "../core/String";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute() {
	return (
		!isEmpty(new Storage().GetSessuinId()) ? <Outlet /> : <Navigate to="login" />
	);
}

export default PrivateRoute;