import Storage from "../core/Storeage";
import { isEmpty } from "../core/String";
import { Navigate, Outlet } from "react-router-dom";

function PublicRoute() {
	return (
		isEmpty(new Storage().GetSessuinId()) ? <Outlet /> : <Navigate to="/" />
	);
}

export default PublicRoute;