import "./Main.scss"
import TopBar from "../layout/TopBar";
import Sidebar from "../layout/Sidebar";
import Blackout from "../layout/Blackout";
import { useState, useEffect } from "react";

function Main() {
	const [isHideSidebar, setIsHideSidebar] = useState(true);
	const [isHideBlackout, setIsHideBlackout] = useState(true);
	
	useEffect(() => {
		document.title = process.env.REACT_APP_NAME;
	}, []);

	function onPressBtnHideOrShowMenu() {
		//let screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
		let newValue = !isHideSidebar;
		setIsHideSidebar(newValue);
		setIsHideBlackout(newValue);
	}

	function onPressBlackout() {
		setIsHideSidebar(true);
		setIsHideBlackout(true);
	}

	return (
		<div className="layout-wrapper layout-static layout-mobile-active p-ripple-disabled">
			<Blackout command={onPressBlackout} hidden={isHideBlackout} />
			<TopBar command={onPressBtnHideOrShowMenu} />
			<Sidebar hidden={isHideSidebar} />
		</div>
	);
}

export default Main;
