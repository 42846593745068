import "./style/Sidebar.scss";
import { useRef, useState } from "react";
import ListItems from "./ListItems";

function Sidebar(props) {
	return(
		<div className={props.hidden ? "layout-sidebar-hide" : "layout-sidebar"}>
			<ListItems />
		</div>
	);
}

export default Sidebar;