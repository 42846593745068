export function isEmpty(value) {
	return value ? false : true;
}

export class StringBuilder {
    strings = new Array("");
    
    constructor(value) {
        this.append(value);    
    }

    append(value) {
        if (value) this.strings.push(value);
    }

    clear () {
        this.strings.length = 1;
    }

    toString() {
        return this.strings.join("");
    }   
}