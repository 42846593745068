import "./style/Item.css"
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
        
function Item(props) {
	const { activeMenu, setActiveMenu } = useState("jkoko");
	const key = props.parentKey ? props.parentKey + '-' + props.index : String(props.index);
	const active = true// activeMenu === key || activeMenu.startsWith(key + '-');
	const isActiveRoute = props.item?.to;

	const subMenu = props.item?.items && props.item?.visible !== false && (
		<CSSTransition timeout={{ enter: 1000, exit: 450 }} classNames="layout-submenu" in={props.root ? true : active} key={props.item?.label}>
			<ul>
				{props.item?.items.map((child, i) => {
					return <Item item={child} index={i} className={child.badgeClass} parentKey={key} key={child.label} />;
				})}
			</ul>
		</CSSTransition>
	);

	function itemClick() {}
	
	return (
		<li className={classNames({ 'layout-root-menuitem': props.root, 'active-menuitem': active })}>
            {props.root && props.item?.visible !== false && <div className="layout-menuitem-root-text">{props.item?.label}</div>}
            {(!props.item?.to || props.item?.items) && props.item?.visible !== false ? (
                <a href={props.item?.url} onClick={(e) => itemClick(e)} className={classNames(props.item?.class, 'p-ripple')} target={props.item?.target} tabIndex={0}>
                    <i className={classNames('layout-menuitem-icon', props.item?.icon)}></i>
                    <span className="layout-menuitem-text">{props.item?.label}</span>
                    {props.item?.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
                    <Ripple />
                </a>
            ) : null}

            {props.item?.to && !props.item?.items && props.item?.visible !== false ? (
                <Link href={props.item?.to} replace={props.item?.replaceUrl} target={props.item?.target} onClick={(e) => itemClick(e)} className={classNames(props.item?.class, 'p-ripple', { 'active-route': isActiveRoute })} tabIndex={0}>
                    <i className={classNames('layout-menuitem-icon', props.item?.icon)}></i>
                    <span className="layout-menuitem-text">{props.item?.label}</span>
                    {props.item?.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
                    <Ripple />
                </Link>
            ) : null}

            {subMenu}
        </li>
    );
}

export default Item;