import "./style/Blackout.css";

function Blackout(props) {
	function onPressComponent() {
		if (props.command) {
			props.command();
		}
	}

	return (
		<div id="blackout" onClick={onPressComponent} hidden={props.hidden} />
	);
}

export default Blackout;